// Utils and Service
import { getErrorMessage } from '../utils/helpers'
import axios from './instance'

/**
 * Gets the Dealers Billing Kpis.
 * @param {func} setLoading
 * @param {func} setError
 * @param {func} setSuccess
 */
export const getDealersBillingKpis = async (
  setLoading,
  setError = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const response = await axios.get(`/dealers/billing-kpis/`)
    setLoading(false)
    setSuccess(true)
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Gets the Dealers Billing Metrics.
 * @param {func} setLoading
 * @param {func} setError
 * @param {func} setSuccess
 */
export const getDealersBillingMetrics = async (
  url,
  setLoading,
  setError = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const response = await axios.get(url)
    setLoading(false)
    setSuccess(true)
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}
