import PropTypes from 'prop-types'
import React from 'react'

// Utils
import { CYCLE_STATES } from '../../utils/constants'

const BillingCycleBadge = ({ status }) => {
  const { text, color } = CYCLE_STATES[status] || {
    text: 'Unknown Status',
    color: 'bg-gray-100 text-gray-600',
  }

  return (
    <span className={`inline-block rounded-full px-3 py-1 text-xs font-medium uppercase ${color}`}>
      {text}
    </span>
  )
}

BillingCycleBadge.propTypes = {
  status: PropTypes.string.isRequired,
}

export default BillingCycleBadge
