export const ACTION_ICONS = {
  'Provide coverage explanation': 'paper-check',
  'Provide labor operation(s)': 'paper-check',
  'Provide approval code (PA)': 'code',
  'Upload/send supporting docs': 'upload',
  'Update customer complaint in DMS': 'person',
  'Provide valid customer complaint': 'person',
  'Update diagnostic code(s)/comments in DMS': 'memo-check',
  'Provide diagnostic code(s)/comments': 'memo-check',
  'Update factory defect comments in DMS': 'memo-check',
  'Provide factory defect comments': 'memo-check',
  'Update repair comments in DMS': 'memo-check',
  'Provide repair comments': 'memo-check',
  'Verify and provide correct mileage': 'mileage',
  'Add advisor/tech to MFG system': 'add',
  'Potential writeoff': 'paper',
  'Other - see problem notes': 'more',
  'Correct parts billed on RO': 'dollar',
  'Need management approval': 'id-badge',
}

export const CYCLE_STATES = {
  'Accepting Memos': {
    text: 'Accepting Memos',
    color: 'bg-gray-200 text-blue-800',
  },
  Approved: {
    text: 'Approved',
    color: 'bg-green-200 text-green-800',
  },
  'Awaiting Review': {
    text: 'Awaiting Review',
    color: 'bg-yellow-200 text-yellow-800',
  },
  Closed: {
    text: 'Closed',
    color: 'bg-black text-white',
  },
  'Not Started': {
    text: 'Not Started',
    color: 'bg-gray-200 text-gray-800',
  },
  Rejected: {
    text: 'Rejected',
    color: 'bg-red-200 text-red-800',
  },
  'Reopened - Accepting Memos': {
    text: 'Reopened - Accepting Memos',
    color: 'bg-purple-200 text-purple-800',
  },
}
