import React, { useEffect, useState } from 'react'
import Dealers from './Dealers'

// Components
import { StateContainer } from '../../components/StateContainer'

// Utils & Service
import { getDealersBillingKpis } from '../../services/billing.service'
import { formatCurrency, formatMonthName } from '../../utils/formatters'

const Billing = () => {
  // State
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)
  const [kpis, setKpis] = useState({})

  const fetchBillingKpis = async () => {
    const data = await getDealersBillingKpis(setLoading, setError)
    if (data) {
      setKpis(data)
    }
  }
  useEffect(() => {
    fetchBillingKpis()
  }, [])

  const currentMonth = formatMonthName(kpis.currentMonth)
  const previousMonth = formatMonthName(kpis.previousMonth)

  return (
    <div className="bg-background  flex h-full w-full flex-col overflow-y-auto px-4 pb-12 pt-6 sm:px-6 lg:px-8">
      <StateContainer async error={error} loading={loading}>
        {() => (
          <>
            <div>
              <div className="mb-8 flex items-center">
                <span className="text-midnight text-2xl font-semibold">Billing</span>
              </div>
              <div className="grid flex-none grid-cols-1 gap-2 sm:grid-cols-2  md:grid-cols-3">
                <div className="bg-midnight col-span-1 row-span-1 flex flex-col items-center justify-center rounded-lg px-3 pb-2 pt-3 sm:col-span-1 md:col-span-1 md:row-span-1">
                  <span className="text-brownGray text-xs uppercase leading-4 tracking-[1.5px]">
                    {currentMonth} Running Total
                  </span>
                  <span className="text-brownGray text-lg font-semibold leading-8 tracking-[-1px]">
                    {formatCurrency(kpis.currentMonthTotal)}
                  </span>
                </div>
                <div className="bg-midnight col-span-1 row-span-1 flex flex-col items-center justify-center rounded-lg px-3 pb-2 pt-3 sm:col-span-1 md:col-span-1 md:row-span-1">
                  <span className="text-brownGray text-xs uppercase leading-4 tracking-[1.5px]">
                    {previousMonth} Total
                  </span>
                  <span className="text-brownGray text-lg font-semibold leading-8 tracking-[-1px]">
                    {formatCurrency(kpis.previousMonthTotal)}
                  </span>
                </div>
                <div className="bg-midnight col-span-1 row-span-1 flex flex-col items-center justify-center rounded-lg px-3 pb-2 pt-3 sm:col-span-1 md:col-span-1 md:row-span-1">
                  <span className="text-brownGray text-xs uppercase leading-4 tracking-[1.5px]">
                    YTD Through {previousMonth}
                  </span>
                  <span className="text-brownGray text-lg font-semibold leading-8 tracking-[-1px]">
                    {formatCurrency(kpis.ytdThruPrevMonth)}
                  </span>
                </div>
              </div>
            </div>
            <Dealers currentMonth={currentMonth} previousMonth={previousMonth} />
          </>
        )}
      </StateContainer>
    </div>
  )
}

export default Billing
